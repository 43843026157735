var Game = {

    _score: 0,

    /**
     * Increase game score
     * @param score
     */
    increaseScore: function (score) {
        Game._score += parseInt(score);
        Game.setUIScore();
    },

    /**
     * Set score value in markup
     */
    setUIScore: function () {
        $(".header__score span").html(Game._score);
    },

    /**
     *
     */
    setUIResult: function () {
        $("#result").attr("data-count", Game._score);
        var mailText = "mailto:?subject=zkm-«Lehrer-Stress-Test»&body=Ich habe " + Game._score + " Punkte erreicht, wie viele erreichst du?";
        $("#mail").attr("href", mailText);

    },

    /**
     * Set progress in header
     */
    setQuestionProgress: function () {
        $(".active_question").html(SlideManager.getCurrentSlideObject().slideIndex);
    },

    setResultScoreText : function(){
        $("#result").attr("data-count", Game._score);
        setTimeout(function(){
            runCountup();
        }, 500);

        if(this._score >= 21) {
            $("[data-scoretype='3']").addClass('active');
        }else if(this._score >= 11) {
            $("[data-scoretype='2']").addClass('active');
        }else{
            $("[data-scoretype='1']").addClass('active');
        }
    },

    /**
     * Start the game
     */
    start: function () {
        SlideManager.reset();
        SlideManager.next();

    },

    /**
     * Reset game
     */
    reset: function () {
        $(".start").removeAttr('disabled');
        AnimationManager.reset();
        SlideManager.reset();
        Game._score = 0;
        Game.setUIScore();
        $(".active_question").html(0);
        $("[data-scoretype]").removeClass('active');
        $(".light-toggle").removeClass("lightning");
    },

    /**
     * Handle answer
     * @param answer
     */
    questionAnswered: function (answer) {

        var currentSlideObject = SlideManager.getCurrentSlideObject();
        var currentSlideIndex = SlideManager.getCurrentSlideIndex();

        currentSlideObject.answer = answer;
        var score = currentSlideObject.answerScore[answer];

        Game.increaseScore(score);

        if(score > 0) { // make icon wiggle if the user scores more than 1
            this.wiggleScoreIndicator(score);
        }

        SlideManager.next(function () {
            AnimationManager.stopAnimation(currentSlideIndex);
        });
    },

    /**
     * Make countdown indicator wiggle
     */
    wiggleCountdownIndicator : function (){
        this._makeElementWiggle(".countdown");
    },

    /**
     * Make score indicator wiggle
     */
    wiggleScoreIndicator : function (){
        $(".header__score .particle-system").addClass("active");
        setTimeout(function(){
            $(".header__score .particle-system").removeClass("active");
        }, 3000);
    },

    /**
     * Apply whigglyness
     * @param {String} selector
     * @private
     */
    _makeElementWiggle : function (selector){
        $(selector).each( function(index, element) {
            element.classList.remove("wiggle");

            // -> triggering reflow /* The actual magic */
            // without this it wouldn't work. Try uncommenting the line and the transition won't be retriggered.
            // Oops! This won't work in strict mode. Thanks Felis Phasma!
            // element.offsetWidth = element.offsetWidth;
            // Do this instead:
            void element.offsetWidth;

            // -> and re-adding the class
            element.classList.add("wiggle");
        });
    },

    /**
     * Show hint
     */
    showHint : function(){
        TimeManager.pauseCountdown();
        $(".hint__backdrop").addClass('show');
        $(".hint").addClass('show');
        Game.hideMeta();
    },

    /**
     * Hide hint
     */
    hideHint : function(){
        TimeManager.resumeCountdown();
        $(".hint__backdrop").removeClass('show');
        $(".hint").removeClass('show');
        Game.showMeta();
    },

    /**
     * show meta option
     */
    showMeta: function () {
        $(".meta__navigation").addClass("show");
    },

    /**
     * hide meta option
     */
    hideMeta: function () {
        $(".meta__navigation").removeClass("show");
    },

    /**
     * show time disturber
     */
    showTimeDisturber: function () {
        $(".time__disturber").addClass("active");
    },

    /**
     * hide time disturber
     */
    hideTimeDisturber: function () {
        $(".time__disturber").removeClass("active");
    }
};