var SoundManager = {
    _currentPlayingInstance: null,
    _backgroundMusicInstance: null,
    _sounds: {},
    _currentSoundFile : null,

    init: function () {

        createjs.Sound.alternateExtensions = ["mp3"];

        createjs.Sound.registerPlugins([createjs.WebAudioPlugin, createjs.HTMLAudioPlugin]);
    },

    /**
     * Stop sound
     */
    stop: function () {
        if(msieversion() !== false){
            SoundManager.ieSoundFuckupFixStop(this._currentSoundFile);
            this._currentSoundFile = null;
        }
        if (this._currentPlayingInstance) {
            this._currentPlayingInstance.stop();
            this._currentPlayingInstance.destroy();
            this._currentPlayingInstance = null;
        }
    },

    /**
     * Stop all audio
     */
    stopAll: function () {
        if (this._currentPlayingInstance) {
            this._currentPlayingInstance.stop();
            this._currentPlayingInstance.destroy();
            this._currentPlayingInstance = null;
        }

        if (this._backgroundMusicInstance) {
            this._backgroundMusicInstance.stop();
            this._backgroundMusicInstance.destroy();
            this._backgroundMusicInstance = null;
        }
    },

    /**
     * Play sound for slide
     * @param {Number} slideIndex
     */
    playSoundBySlideIndex: function (slideIndex) {
        SoundManager.stopFile("/dist/audio/07_Elektrisches_Summen.ogg");

        var animationSlideKeys = Object.keys(animationSlides);
        var animationSlideKeysLength = animationSlideKeys.length;

        for (var i = 0; i < animationSlideKeysLength; i++) {
            var animationSlideKey = animationSlideKeys[i];
            var animationSlide = animationSlides[animationSlideKey];
            var previousAnimationSlide = animationSlides[(i - 1 > 0 ? animationSlideKeys[i - 1] : animationSlideKeys[i])];


            if (parseInt(animationSlide.slideIndex) !== parseInt(slideIndex)) {
                continue;
            }

            this.stop();
            if(!animationSlide.audio){
                continue;
            }

            var slideObject = SlideManager.getCurrentSlideObject();
            if (slideObject.timeDisturber === true) {
                this.playFile("/dist/audio/00_Uhr.ogg");
                setTimeout(this._stopClockSoundClosure(animationSlide), SlideManager.disturberDuration);
            } else {
                this._playAudio(animationSlide)
            }
        }
    },

    _stopClockSoundClosure: function (animationSlide) {
        return function () {
            SoundManager.stopFile("/dist/audio/00_Uhr.ogg");
            SoundManager._playAudio(animationSlide)
        }
    },

    /**
     *
     * @param {Object} slide
     * @private
     */
    _playAudio: function (slide) {
        if (!slide) {
            console.error('No slide given');
            return;
        }

        if (msieversion() !== false) {
            this._currentSoundFile = slide.audio;
            SoundManager.ieSoundFuckupFixPlay(slide.audio, true);
            return;
        }

        if (!AssetManager._loadedAssets[slide.audio]) {
            console.warn('Audio', 'File ' + slide.audio + ' not loaded');
            return;
        }


        this._currentPlayingInstance = createjs.Sound.play(slide.audio);
        this._currentPlayingInstance.loop = -1;
    },
    /**
     *
     * @param {String} file
     * @param {Number} volume
     */
    playFile: function (file, volume) {
        volume = volume || 1;

        if (msieversion() !== false) {
            SoundManager.ieSoundFuckupFixPlay(file, true, volume);
            return;
        }

        if (!AssetManager._loadedAssets[file]) {
            console.warn('Audio', 'File ' + file + ' not loaded');
            return;
        }

        var sound = createjs.Sound.play(file, {loop: -1, volume: volume});
        sound.volume = volume;
        sound.loop = -1;
        if (this._sounds[file]) {
            this._sounds[file].stop();
            this._sounds[file].destroy();
            this._sounds[file] = null;
        }
        this._sounds[file] = sound;
    },

    /**
     *
     * @param file
     * @param loop
     * @param volume
     */
    ieSoundFuckupFixPlay: function (file, loop, volume) {
        if(!file){
            return;
        }
        loop = loop || false;
        volume = volume || 1;

        var ext = file.split('.');
        ext[1] = 'mp3';
        ieSound.play(ext.join('.'), loop, volume);
    },

    /**
     *
     * @param file
     */
    ieSoundFuckupFixStop: function (file) {
        if(!file){
            return;
        }
        var ext = file.split('.');
        ext[1] = 'mp3';
        ieSound.stop(ext.join('.'));
    },

    /**
     *
     * @param file
     */
    stopFile: function (file) {
        if (msieversion() !== false) {
            SoundManager.ieSoundFuckupFixStop(file);
            return;
        }

        if (this._sounds[file] !== null && this._sounds[file] !== undefined) {
            this._sounds[file].stop();
            this._sounds[file].destroy();
            this._sounds[file] = null;
        }
    }
};