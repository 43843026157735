(function ($) {
    $("#subscribeToContest").click(function (e) {
        e.preventDefault();
        $(this).attr('disabled', true);
        $("#form-error").removeClass('active');

        var name = $("[name='name']").get(0);
        var prename = $("[name='prename']").get(0);
        var mail = $("[name='mail']").get(0);

        $.post({
            url: '/subscribe.php',
            data: {
                name: name.value,
                prename: prename.value,
                mail: mail.value
            }
        })
            .done(function (data) {
                data = JSON.parse(data);
                $("#form-error").removeClass('active');
                $("input.error").removeClass('error');

                if(data.error){
                    $("#form-error").addClass('active');
                    $("input[name='"+data.error+"']").addClass('error');
                    return;
                }
                SlideManager.next();
            })
            .fail(function() {
                $("#form-error").addClass('active');
            })
            .always( function(){
                $("#subscribeToContest").removeAttr('disabled');
                //SlideManager.next();
            })
    })
})(jQuery);