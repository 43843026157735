"use strict";

var AnimationManager = {

    /**
     * play animation for given slideIndex
     * @param index
     */
    playAnimation: function (index) {
        var keys = Object.keys(animationSlides);
        var len = keys.length;

        for (var i = 0; i < len; i++) {
            var key = keys[i];
            var slide = animationSlides[key];

            if(parseInt(slide.slideIndex) !== parseInt(index)){
                continue;
            }

            var slideAnimations = slide.animations;
            var animLength = slideAnimations.length;

            for (var j = 0; j < animLength; j++) {
                var animation = slideAnimations[j];
                var asset = AssetManager.getAsset(animation.jsonfile);
                if (!animation.elementid) {
                    continue;
                }

                var element = document.getElementById(animation.elementid);
                if (!element) {
                    continue;
                }

                var comp = new SVGAnim(
                    asset.content,
                    animation.width,
                    animation.height,
                    animation.fps
                );

                $(comp.s.node).removeAttr('width');
                $(comp.s.node).removeAttr('height');
                $(comp.s.node).attr('viewBox', "0, 0, "+animation.width+", "+animation.height);

                element.appendChild(comp.s.node);
            }
        }
    },

    /**
     * stop animations on specific slide
     * @param index
     */
    stopAnimation : function(index){
        var keys = Object.keys(animationSlides);
        var len = keys.length;

        for (var i = 0; i < len; i++) {
            var key = keys[i];
            var slide = animationSlides[key];
            if(parseInt(slide.slideIndex) !== parseInt(index)){
                continue;
            }
            var slideAnimations = slide.animations;
            var animLengh = slideAnimations.length;

            for (var j = 0; j < animLengh; j++) {
                var animation = slideAnimations[j];
                if (!animation.elementid || animation.removeable === false) {
                    continue;
                }

                var element = document.getElementById(animation.elementid);
                if (!element) {
                    continue;
                }
                $(element).empty();
            }
        }
    },

    /**
     * reset all animations
     */
    reset : function(){
        var keys = Object.keys(animationSlides);
        var len = keys.length;

        for (var i = 0; i < len; i++) {
            var key = keys[i];
            var slide = animationSlides[key];

            var slideAnimations = slide.animations;
            var animLengh = slideAnimations.length;

            for (var j = 0; j < animLengh; j++) {
                var animation = slideAnimations[j];
                if (!animation.elementid) {
                    continue;
                }

                var element = document.getElementById(animation.elementid);
                if (!element) {
                    continue;
                }

                $(element).empty();
            }
        }
    }
};