(function(){
    window.ieSound = {
        _sounds : {},

        /**
         * play sound by given file name.
         * @param {String} file
         * @param loop
         * @param volume
         */
        play : function(file, loop, volume){
            var sound = document.createElement('bgsound');
            sound.src = file;
            sound.loop = "infinite";
            sound.volume = volume;
            sound.autostart = "autostart";
            document.body.appendChild(sound);
            window.ieSound._sounds[file] = sound;
        },

        /**
         * stop sound by given filename
         * @param file
         */
        stop : function(file){
            var sound = window.ieSound._sounds[file];
            if(!sound){
                return;
            }
            document.body.removeChild(sound);
            delete window.ieSound._sounds[file];
        }
    }
})();