/***************************************************************************************/
// INIT FULLPAGE
/***************************************************************************************/
var fullpage = {
    _nextSlideLeaveCallback: function () {
    },
    init: function () {
        this.setup();
    },
    setup: function () {
        $('#fullpage').fullpage({
            //Navigation
            navigation: false,
            showActiveTooltip: false,
            slidesNavigation: false,

            //Scrolling
            css3: true,
            scrollingSpeed: 700,
            autoScrolling: true,
            fitToSection: true,
            fitToSectionDelay: 1000,
            scrollBar: false,
            easing: 'easeInOutCubic',
            easingcss3: 'ease',
            loopBottom: false,
            loopTop: false,
            loopHorizontal: false,
            continuousVertical: false,
            continuousHorizontal: false,
            scrollHorizontally: false,
            interlockedSlides: false,
            dragAndMove: false,
            offsetSections: false,
            resetSliders: false,
            fadingEffect: true,
            normalScrollElements: '',
            scrollOverflow: false,
            scrollOverflowReset: false,
            scrollOverflowOptions: null,
            touchSensitivity: 15,
            normalScrollElementTouchThreshold: 5,
            bigSectionsDestination: null,

            //Accessibility
            keyboardScrolling: false,
            animateAnchor: false,
            recordHistory: false,

            //Design
            controlArrows: false,
            verticalCentered: true,
            sectionsColor: ['#009CD8', 'transparent', 'transparent', 'transparent', 'transparent', 'transparent', 'transparent', 'transparent', 'transparent', '#009CD8', "#009CD8", "#009CD8"],
            paddingTop: '0em',
            paddingBottom: '0px',
            fixedElements: '.header, .answer',
            responsiveWidth: 0,
            responsiveHeight: 0,
            responsiveSlides: false,
            parallax: false,
            parallaxOptions: {type: 'reveal', percentage: 62, property: 'translate'},

            //Custom selectors
            sectionSelector: '.section',
            slideSelector: '.slide',

            lazyLoading: true,

            //events
            onLeave: function (index, nextIndex, direction) {
                 if (fullpage._nextSlideLeaveCallback) {
                    fullpage._nextSlideLeaveCallback(); // call callback and remove it afterwards
                    fullpage._nextSlideLeaveCallback = function () {
                    };
                }

                SoundManager.stop();
            },
            afterLoad: function (anchorLink, index) {
                $(".section" + (index - 1)).attr('data-loaded', true);
            },
            onSlideLeave : function(anchorLink, index, slideIndex, direction, nextSlideIndex){
                SoundManager.stop();
            }
        });

        $.fn.fullpage.setKeyboardScrolling(false);
        $.fn.fullpage.setAllowScrolling(false);
        $.fn.fullpage.setLockAnchors(true);
    }
};
