/***************************************************************************************/
// RESULT CALCULATION
/***************************************************************************************/
var EventListener = {
    _answerTimeout: null,
    init: function () {
        $(".start span").html('Los!');
        $(".start").addClass('ready');
        $(".start").on("click", function (e) {
            e.preventDefault();
            $(this).attr('disabled', true);

            ga('send', {
                hitType: 'event',
                eventCategory: 'Game',
                eventAction: 'play',
                eventLabel: 'Start Game'
            });

            setTimeout(function () {
                Game.start();
            }, 200)
        });

        $("[data-more]").on('click', function(e) {
            ga('send', {
                hitType: 'event',
                eventCategory: 'Info',
                eventAction: 'click',
                eventLabel: ' Erfahre mehr über die Mitgliedervorteile'
            });
        });

        $(".fp-next").on('click', function (e) {
            e.preventDefault();
            SlideManager.next();
        });

        $(".answer__wrapper").on("click", ".btn.question", function (e) {
            e.preventDefault();

            var isDisabled = $(this).attr('disabled');
            if (typeof isDisabled !== typeof undefined && isDisabled !== false) {
                return;
            }
            if(EventListener._answerTimeout) {
                return;
            }
            TimeManager.stopCountdown();

            if(parseInt($(this).attr("data-hinttrigger")) === 1){
                Game.showHint();
                return;
            }

            var answer = $(this).index();

            EventListener._answerTimeout = setTimeout(function () {
                Game.questionAnswered(answer);
                EventListener._answerTimeout = null;
            }, 500);
        });

        $("[data-closehint]").on('click', function(){
            Game.hideHint();
        });

        $(".btn__replay").on("click", function (e) {
            ga('send', {
                hitType: 'event',
                eventCategory: 'Game',
                eventAction: 'replay',
                eventLabel: 'Restart Game'
            });
        });

        $(".illustration__light-switch").on("click", function () {
            $(".light-toggle").toggleClass("lightning");

            if($(".light-toggle").hasClass("lightning")){
                $(".light-toggle .btn.question").removeAttr("disabled");
                SoundManager.playFile("/dist/audio/07_Elektrisches_Summen.ogg");
            }else{
                $(".light-toggle .btn.question").attr("disabled", true);
                SoundManager.stopFile("/dist/audio/07_Elektrisches_Summen.ogg");
            }
        });
    }
};
