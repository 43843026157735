/***************************************************************************************/
// SLIDER FUNCTIONS
/***************************************************************************************/
var SlideManager = {
    _currentSlideIndex : 0,
    disturberDuration : 4000,
    /**
     * Reset slides
     */
    reset : function(){
        this._currentSlideIndex = 0;
        this.showSlide(this._currentSlideIndex);
    },

    /**
     * Go to next slide/section
     * @param {Function} cb
     */
    next : function( cb){
        AnimationManager.stopAnimation(this.currentSlideObj(this._currentSlideIndex));
        this._currentSlideIndex += 1;
        this.showSlide(this._currentSlideIndex, cb);
    },

    /**
     * show slide by index
     * @param index
     * @param cb
     */
    showSlide : function(index, cb){
        var currentSlideObject = this.currentSlideObj(index);

        ga('send', {
            hitType: 'event',
            eventCategory: 'Slide',
            eventAction: 'show_'+index,
            eventLabel: 'Show slide'
        });

        //restart animations for slide
        AnimationManager.playAnimation(currentSlideObject.slideIndex);
        Game.setQuestionProgress(currentSlide);

        if(currentSlideObject.metaHide === true) {
            Game.hideMeta();
        } else {
            Game.showMeta();
        }

        if (currentSlideObject.timeDisturber === true) {
            Game.showTimeDisturber();
            setTimeout(function () {
                Game.hideTimeDisturber();
            }, this.disturberDuration);
            setTimeout(function () {
                Game.wiggleCountdownIndicator();
            }, this.disturberDuration + 300 );
        }

        $.fn.fullpage.moveTo(currentSlideObject.section, currentSlideObject.slide);
        fullpage._nextSlideLeaveCallback = cb;

        if(currentSlideObject.slideDuration > 0) {
            TimeManager.setTime(currentSlideObject.slideDuration);
            // TimeManager.setTime(120);
            TimeManager.startCountdown();
        }

        SoundManager.playSoundBySlideIndex(currentSlideObject.slideIndex);

        if(currentSlideObject.isLast === true){
            Game.setUIResult();
            TimeManager.stopCountdown();
            Game.hideMeta();
            Game.setResultScoreText();
            SoundManager.stopAll();
        }
    },

    /**
     *
     * @returns {*|null}
     */
    getCurrentSlideObject : function (){
        return this.currentSlideObj(this._currentSlideIndex);
    },

    /**
     *
     * @returns {number}
     */
    getCurrentSlideIndex : function(){
        return this._currentSlideIndex;
    },

    /**
     * Check if is last section
     * @returns {boolean}
     */
    isLast : function(){
        var name = Object.keys(slides)[this._currentSlideIndex];
        var section = slides[name];
        if(section.isLast === true){
            return true;
        }
        return false;
    },


    /**
     * MAGIC
     * @param id
     * @returns {null}
     */
    currentSlideObj: function(id) {
        $output = null;

        $.each(slides, function( key, value ) {
            if(value.slideIndex == id) {
                $output =  value;
            }
        });

        return $output
    }
};