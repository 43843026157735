 $(window).ready( function() {
     return;
     setTimeout(function () {
         $("#start").click();
         fukingNextSlide();
     },2000);

 });

var fukingNextSlide_it = 10, fukingNextSlide_c = 0;

function fukingNextSlide() {
    fukingNextSlide_c++;

    setTimeout( function(){
        if($(".section.active .slide.active a.btn.question").get(1)) {
            $($(".section.active .slide.active a.btn.question").get(1)).click();
        }else{
            $($(".section.active a.btn.question").get(1)).click();
        }
    }, 1000)

    if(fukingNextSlide_c < fukingNextSlide_it){
        setTimeout(function(){
            fukingNextSlide()
        }, 1000);
    }
}






 function msieversion() {
     var ua = window.navigator.userAgent;
     var msie = ua.indexOf("MSIE ");
     if (msie > -1) {
         return ua.substring(msie + 5, ua.indexOf(".", msie));
     } else if (navigator.userAgent.match(/Trident.*rv\:11\./)) {
         return 11;
     } else {
         return false;
     }
 }