var AssetManager = {
    _assets: [],
    _soundAssets: [],
    _loadedAssets : [],

    TYPE_AUDIO: 'audio',
    TYPE_JSON: 'json',


    _onLoadedCallback: function () {
    },
    _onAssetLoadedCallback: function () {
    },

    /**
     * Add asset to pipeline to be loaded
     * @param {String} type asset type [json|audio]
     * @param {String} file Url/path of the asset
     */
    require: function (type, file) {
        if (type !== AssetManager.TYPE_AUDIO && type !== AssetManager.TYPE_JSON) {
            throw Error('Invalid type [json|audio]')
        }
        if(this.getAsset(file)){
            return;
        }

        if(type === AssetManager.TYPE_AUDIO && msieversion() ){
            return;
        }

        this._assets.push({
            type: type,
            file: file,
            loaded: false,
            content: ""
        });
    },

    /**
     * Get an asset object
     * @param asset
     * @returns {Object}
     */
    getAsset: function (asset) {
        var assets = this._assets;
        var len = assets.length;

        for (var i = 0; i < len; i++) {
            var _asset = assets[i];
            if (_asset.file === asset) {
                return _asset;
            }
        }
        return null;
    },

    /**
     * Load all files in the animations slides config
     */
    prepare: function () {
        var keys = Object.keys(animationSlides);
        var len = keys.length;
        for (var i = 0; i < len; i++) {
            var key = keys[i];
            var animation = animationSlides[key];
            this._addAnimations(animation.animations);
            this._addAudio(animation);
        }

        createjs.Sound.on("fileload", function (event) {
            AssetManager._onAudioLoadedCallback(event);
        });
    },

    /**
     * load all assets
     * @param {Function} _onAssetLoadedCallback callback when a single asset is complete
     * @param {Function} onLoadedCallback callback when loaded is completed
     */
    load: function (_onAssetLoadedCallback, onLoadedCallback) {
        _onAssetLoadedCallback = _onAssetLoadedCallback || function () {};
        onLoadedCallback = onLoadedCallback || function () {};

        if (!typeof onLoadedCallback === 'function' || !typeof onLoadedCallback === 'function') {
            throw new Error('Callback is not a function!');
        }

        this._onAssetLoadedCallback = _onAssetLoadedCallback;
        this._onLoadedCallback = onLoadedCallback;

        var assets = this._assets;
        var len = assets.length;

        for (var i = 0; i < len; i++) {
            var asset = assets[i];
            this._loadAsset(asset);
        }
    },

    /**
     * Load specific asset
     * @param {Object} asset
     * @private
     */
    _loadAsset: function (asset) {
        if (asset.type === AssetManager.TYPE_AUDIO) {
            this._loadAudio(asset);
        }
        if (asset.type === AssetManager.TYPE_JSON) {
            this._loadJSON(asset);
        }
    },

    /**
     *
     * @param asset
     * @private
     */
    _loadJSON : function (asset){
        $.ajax({
            url: asset.file
        })
            .fail( function(data){
                console.error("Could not load", asset.file);
                Raven.captureMessage('Could not load '+asset.file);
            })
            .done(function (data) {
                asset.loaded = true;
                asset.content = data;
                this._loadedAssets[asset.file] = true;
                this._onAssetLoadedCallback();
                if (this._checkIfLoadedComplete()) {
                    this._onLoadedCallback();
                }
            }.bind(this));
    },

    /**
     * Load audio asset
     * @param {Object} asset
     * @private
     */
    _loadAudio : function (asset){
        createjs.Sound.registerSound(asset.file, asset.file);
    },

    /**
     * add all animations to pipeline
     * @param {Array} animations
     * @private
     */
    _addAnimations: function (animations) {
        var len = animations.length;
        for (var i = 0; i < len; i++) {
            var animation = animations[i];
            this.require(AssetManager.TYPE_JSON, animation.jsonfile);
        }
    },

    /**
     * Add audio asset to the loading pipeline
     * @param {Object} conf
     * @private
     */
    _addAudio: function (conf) {
        if (!conf.audio) {
            return;
        }

        this.require(AssetManager.TYPE_AUDIO, conf.audio);
    },

    /**
     * Called when an audio asset is fully loaded
     * @param {Object} event
     * @private
     */
    _onAudioLoadedCallback: function (event) {
        var asset = this.getAsset(event.id);
        this._loadedAssets[asset.file] = true;
        asset.loaded = true;
        this._onAssetLoadedCallback();
        if (this._checkIfLoadedComplete()) {
            this._onLoadedCallback();
        }
    },

    /**
     * Check if all assets have been loaded
     * @returns {boolean}
     * @private
     */
    _checkIfLoadedComplete: function () {
        var assets = this._assets;
        var len = assets.length;

        for (var i = 0; i < len; i++) {
            var asset = assets[i];
            if (asset.loaded === false) {
                return false;
            }
        }

        return true;
    },

    /**
     *
     * @returns {Number}
     *
     */
    getTotalAssets: function () {
        return this._assets.length;
    },

    /**
     *
     * @returns {number}
     */
    getProgress: function () {
        var assets = this._assets;
        var len = assets.length;
        var loaded = 0;

        for (var i = 0; i < len; i++) {
            var _asset = assets[i];
            if (_asset.loaded === true) {
                loaded++;
            }
        }
        return loaded;
    }
};