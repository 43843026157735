"use strict";

var TimeManager = {
    _timeout: null,
    _updateInterval: null,
    _countdown: 0,

    /**
     * Set time for countdown
     * @param {Number} time
     */
    setTime: function (time) {
        this.stopCountdown();


        if(time === null || time === 0){
            return;
        }
        Game.wiggleCountdownIndicator();
        $("#countdown-circle").css("animation","countdown "+(time+1)+"s linear infinite forwards");
        $(".countdown-number").html(time);
        this._countdown = time;
        this._timeout = setTimeout(function(){
            TimeManager.onTimePassed();
        }, time * 1000);
    },

    /**
     * stop the interval
     */
    stopCountdown : function(){
        $("#countdown-circle").css("animation", "none");
        clearInterval(this._updateInterval);
        clearTimeout(this._timeout);
    },

    pauseCountdown : function(){
        $("#countdown-circle").css("animation", "none");
        clearInterval(this._updateInterval);
        clearTimeout(this._timeout);
    },

    resumeCountdown : function(){
        $("#countdown-circle").css("animation","countdown "+(this._countdown+1)+"s linear infinite forwards");
        this.startCountdown();
        this._timeout = setTimeout(function(){
            TimeManager.onTimePassed();
        }, this._countdown * 1000);
    },


    /**
     * Called when the time is over
     */
    onTimePassed : function(){
        this.stopCountdown();
        SlideManager.next();
    },

    /**
     * Start countdown interval
     */
    startCountdown: function () {
        this._updateInterval = setInterval(function () {
            TimeManager._countdown--;
            if(TimeManager._countdown <= 5){
                Game.wiggleCountdownIndicator();
            }
            $(".countdown-number").html(TimeManager._countdown);
        }, 1000);
    }
};