/***************************************************************************************/
// ANIMATION SLIDE OPTIONS
/***************************************************************************************/
var fps = 60;
var animationSlides = {
    slide0: {
        "slideIndex": 0,
        "fps": fps,
        "animations": [
            {
                "elementid" : "intro_illustration1",
                "jsonfile" : "dist/json/screen-1.json",
                "width" : 228,
                "height" : 228
            }
        ]
    },
    slide1: {
        "slideIndex": 1,
        "fps": fps,
        "animations": [
            {
                "elementid": 'time__disturber',
                "jsonfile": "dist/json/zwischen_screen_zeit.json",
                "width": 700,
                "height": 564,
                "removeable" : false
            },
            {
                "elementid": 'section1_illustration1',
                "jsonfile": "dist/json/screen-2-links.json",
                "width": 214,
                "height": 215
            },
            {
                "elementid": 'section1_illustration2',
                "jsonfile": "dist/json/screen-2-rechts.json",
                "width": 223,
                "height": 147
            }
        ],
        "audio": "/dist/audio/01_Madchengeschrei.ogg"
    },

    slide2: {
        "slideIndex": 2,
        "fps": fps,
        "animations": [
            {
                "elementid": 'section2_illustration1',
                "jsonfile": "dist/json/screen-3.json",
                "width": 375,
                "height": 198
            },
            {
                "elementid": 'section2_illustration2',
                "jsonfile": "dist/json/screen-3-maedchen.json",
                "width": 179,
                "height": 205
            },
        ],
        "audio": "/dist/audio/02_Tippgerausche.ogg"
    },

    slide3: {
        "slideIndex": 3,
        "fps": fps,
        "animations": [
            {
                 "elementid": 'section3_illustration3',
                 "jsonfile": "dist/json/screen-4-globus.json",
                 "width": 171,
                 "height": 230
            },
            {
                "elementid": 'section3_illustration2',
                "jsonfile": "dist/json/screen-4-lehrerin.json",
                "width": 430,
                "height": 564
            }
        ],
        "audio": "/dist/audio/03_Fluchen_Franz.ogg"
    },

    slide4: {
        "slideIndex": 4,
        "fps": fps,
        "animations": [

            {
                "elementid": "section4_slide1_illustration1",
                "jsonfile": "dist/json/screen-5.json",
                "width": 328,
                "height": 545
            }
        ],
        "audio" : "/dist/audio/04_Nervgerausche.ogg"
    },

    slide5: {
        "slideIndex": 5,
        "fps": fps,
        "animations": [
            {
                "elementid": "section4_slide2_illustration3",
                "jsonfile": "dist/json/screen-6.json",
                "width": 222,
                "height": 375
            }
        ],
        "audio": "/dist/audio/05_TickTackBaby.ogg"
    },

    slide6: {
        "slideIndex": 6,
        "fps": fps,
        "animations": [
            {
                "elementid": "section4_slide3_illustration1",
                "jsonfile": "dist/json/screen-7.json",
                //"width": 137,
                "width": 550,
                //"height": 431
                "height": 900
            },
            {
                "elementid": "section4_slide3_illustration2",
                "jsonfile": "dist/json/screen-7-maedchen.json",
                "width": 140,
                "height": 310
            },
        ],
        "audio" : "/dist/audio/06_Schluchzen_WhatsApp.ogg"
    },


    slide8: {
        "slideIndex": 8,
        "fps": fps,
        "animations": [
        ],
        "audio" : "/dist/audio/08_Murmeln_Flustern.ogg"
    },

    slide10: {
        "slideIndex": 9,
        "fps": fps,
        "animations": [
            {
                "elementid": "section7_illustration1",
                "jsonfile": "dist/json/screen-12-junge.json",
                "width": 495,
                "height": 645
            }, {
                "elementid": "section7_illustration2",
                "jsonfile": "dist/json/screen-12.json",
                "width": 328,
                "height": 322
            }
        ],
        "audio" : "/dist/audio/09_Quietschen_Schaukelpferd.ogg"
    },

    slide11: {
        "slideIndex": 10,
        "fps": fps,
        "animations": [
            {
                "elementid": "section8_illustration1",
                "jsonfile": "dist/json/screen-13.json",
                "width": 700,
                "height": 685
            },
        ],
        "audio" : "/dist/audio/10_Feriengedudel.ogg"
    },

    result: {
        "slideIndex": 11,
        "jsonfile": null,
        "fps": null,
        "width": null,
        "height": null,
        "animations": []
    },

    end: {
        "slideIndex": null,
        "jsonfile": null,
        "fps": null,
        "width": null,
        "height": null,
        "animations": []
    }
};