/***************************************************************************************/
// SLIDER OPTIONS
/***************************************************************************************/

var slides = {
    intro: {
        "section": 1,
        "slideIndex": 0,
        "slideDuration": null,
        "metaHide": true
    },
    slide1: {
        "section": 2,
        "slideIndex": 1,
        "slideDuration": 45,
        "hint": false,
        "answer": null,
        "answerScore": [1, 3, 0, 2],
        "timeDisturber": false,
        "slide": 0,
        "metaHide": false,
    },
    slide2: {
        "section": 3,
        "slideIndex": 2,
        "slideDuration": 45,
        "hint": false,
        "answer": null,
        "answerScore": [1, 2, 0, 3],
        "timeDisturber": false,
        "slide": 0,
        "metaHide": false
    },
    slide3: {
        "section": 4,
        "slideIndex": 3,
        "slideDuration": 45,
        "hint": false,
        "answer": null,
        "answerScore": [0, 3, 1, 2],
        "timeDisturber": false,
        "slide": 0,
        "metaHide": false
    },
    slide4: {
        "section": 5,
        hasSlides: true,
        "slideIndex": 4,
        "slideDuration": 40,
        "hint": false,
        "answer": null,
        "answerScore": [0,3,1,2],
        "timeDisturber": true,
        "slide": 0,
        "metaHide": false
    },
    slide5: {
        "section": 5,
        hasSlides: true,
        "slideIndex": 5,
        "slideDuration": 40,
        "hint": false,
        "answer": null,
        "answerScore": [2, 0, 3, 1],
        "timeDisturber": false,
        "slide": 1,
        "metaHide": false
    },
    slide6: {
        "section": 5,
        hasSlides: true,
        "slideIndex": 6,
        "slideDuration": 40,
        "hint": false,
        "answer": null,
        "answerScore": [2,0,1,3],
        "timeDisturber": false,
        "slide": 2,
        "metaHide": false
    },
    slide7: {
        "section": 6,
        "slideIndex": 7,
        "slideDuration": 40,
        "hint": true,
        "answer": null,
        "answerScore": [0,1,2,0],
        "timeDisturber": true,
        "slide": 0,
        "metaHide": false
    },
    slide8: {
        "section": 7,
        "slideIndex": 8,
        "slideDuration": 30,
        "hint": false,
        "answer": null,
        "answerScore": [3, 1, 0, 2],
        "timeDisturber": false,
        "slide": 0,
        "metaHide": false
    },
    slide9: {
        "section": 8,
        "slideIndex": 9,
        "slideDuration": 30,
        "hint": false,
        "answer": null,
        "answerScore": [2, 0, 3, 1],
        "timeDisturber": true,
        "slide": 0,
        "metaHide": false
    },
    slide10: {
        "section": 9,
        "slideIndex": 10,
        "slideDuration": 30,
        "hint": false,
        "answer": null,
        "answerScore": [2, 1, 0, 3],
        "timeDisturber": false,
        "slide": 0,
        "metaHide": false,
    },
    result: {
        "section": 10,
        hasSlides: true,
        "slideIndex": 11,
        "slideDuration": null,
        "hint": false,
        "answer": null,
        "timeDisturber": false,
        "slide": 0,
        "metaHide": true,
        isLast: true

    },
    signup: {
        "section": 10,
        hasSlides: true,
        "slideIndex": 12,
        "slideDuration": null,
        "hint": false,
        "answer": null,
        "timeDisturber": false,
        "slide": 1,
        "metaHide": true
    },
    thanks: {
        "section": 10,
        hasSlides: true,
        "slideDuration": null,
        "slideIndex": 13,
        "hint": false,
        "answer": null,
        "timeDisturber": false,
        "slide": 2,
        "metaHide": true
    },
    end: {
        "slideNext": null,
        "slideDuration": null,
        "metaHide": true

    }
};
