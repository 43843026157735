/***************************************************************************************/
// DOCUMENT READY
/***************************************************************************************/
$(document).ready(function () {

    $('#fragmentHider').css('opacity', 1);

    var bar = new ProgressBar.Circle('#start', {
        strokeWidth: 12,
        duration: 2000,
        color: '#ffffff ',
        easing: 'easeInOut'
    });

    fullpage.init();
    SoundManager.init();

    AssetManager.prepare();
    AssetManager.require(AssetManager.TYPE_AUDIO, "/dist/audio/00_Uhr.ogg");
    AssetManager.require(AssetManager.TYPE_AUDIO, "/dist/audio/00_Hintergrundmusik.ogg");
    AssetManager.require(AssetManager.TYPE_AUDIO, "/dist/audio/07_Elektrisches_Summen.ogg");

    //



    AssetManager.load(function () {
            var progress = AssetManager.getProgress() / AssetManager.getTotalAssets();
            bar.animate(progress, {
                duration: 1
            });
        },

        function () {
            SoundManager.playFile("/dist/audio/00_Hintergrundmusik.ogg", 0.50);
            AnimationManager.playAnimation(0);

            EventListener.init();
        }
    );


});
